.abolishCompanyButton {
  margin: 0 10px;
  padding: 0 5px;
}


.abolishCompanyModalTitle {
}

.abolishCompanyModalError {
  color: red;
  font-weight:bold;
}

.abolishCompanyList {
  list-style-type: disc;
  margin: 10px 0;
}

