 .customButtonColor {
   background-color: rgb(100, 100, 100) ;
   color: #fff;
   margin-left:5px;
 }
 .customButtonColor:hover, .customButtonColor:focus {
     background-color: #fff;
     color:rgb(100, 100, 100);
     border-color: rgb(100, 100, 100);
 }

 /* 有効期間微調整 */
 .label {
   margin-right:8px;
   margin-left:8px;
 } 
 .deadLineDuration {
   display: flex;
   flex-direction: row;
   align-items: center;
   width: auto !important;
 }
 .ceilingDailyAllowance {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto !important;
 }
 .note {
  text-align: left;
  width: auto !important;
 }
 /* .deadlineSpan {
   margin-left:15px;
  display: inline-block;
 } */
 /* @media (max-width: 575px) {
  .deadlineSpan {
    margin-left:25px;
  }
 
} */

/* 入力項目の幅指定 */
.originalQualificationNo {
  ime-mode: disabled;
  width:45% !important;
  height: 32px;
}
.originalQualificationName {
  width:90% !important;
  height: 32px;
}
.allowanceTime {
  width:180px !important;
  margin: 0 !important;
  padding: 0;
  text-align: right!important;
}
.allowanceDays {
  width:150px !important;
  margin: 0 !important;
  padding: 0;
  text-align: right!important;
}
.allowanceAmount {
  width:120px !important;
  text-align: right!important;
}
.ceilingDailyAllowanceInspectionDays{
  width:180px !important;
  text-align: right!important;
}
.ceilingDailyAllowanceAmount {
  width:180px !important;
  text-align: right!important;
}
.startDate {
  width: 150px;
  display: inline-block;
  margin-right: 0 !important;
}
.endDate {
  width: 150px;
  display: inline-block;
  margin-left: 0 !important;
  padding: 0 !important;
}
.deadlineExist {
  margin: 0;
}

/* ボタンエリア固定化 */
.center {
  text-align: center;
}
.float {
  position: fixed!important;
  position : absolute; 
  bottom: 40px;
  left: 45%;
}
.float button {
  float: left;
  margin: 5px;
}
.buttonArea {
  background-color: #fff;
  min-height: 50px;
  bottom:56px;
}