.functionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(0, 106, 93);
  text-align: left;
  float: left;
}
.sarchCriteria {
  justify-content: flex-end;
  margin-bottom: 10px;
}
.criteriaSearchField {
  float:right;
}
.formItem {
  display: inline;
}