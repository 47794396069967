.HeaderArea {
  padding: 14px 14px 6px 14px;
  min-height: 54px;
}
.HeaderlogoArea {
  display:-webkit-flex;
	display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
}
.LogoStyle {
  height:40px;
}
.HeaderMenu {
  padding:4px 0px 4px 0px;
  color:  #fff !important;
  background-color:rgb(39,166,155)  !important;
  font-weight: 600;
  display:-webkit-flex;
	display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  min-height:48px;
}
.mainMessage {
  margin-top : 50px;
  font-size: 20px;
  font-weight: bold;
}