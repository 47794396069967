.functionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(0, 106, 93);
  text-align: left;
}

.layout {
  height: 100%;
}

.errorMessage {
  color: red;
  font-weight:600;
  font-size: 1.1em;
 }