.HeaderMenu {
  padding:0px;
  color:  #fff !important;
  background-color:rgb(0, 106, 93)  !important;
  border: 3px solid rgb(0, 106, 93) ;
  font-weight: 600;
  display:-webkit-flex;
	display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
}
.PartnerHeaderMenu {
  padding:0px;
  color:  #fff !important;
  background-color:rgb(39,166,155)  !important;
  border: 3px solid rgb(39,166,155) ;
  font-weight: 600;
  display:-webkit-flex;
	display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
}
.HeaderMenuItem {
  border-radius: 6px;
	-webkit-border-radius: 6px;	/* Safari,Google Chrome用 */
	-moz-border-radius: 6px;	 /* Firefox用 */
}
.HeaderMenuRightStartItem {
  margin-left:auto;
  border-radius: 6px;
	-webkit-border-radius: 6px;	/* Safari,Google Chrome用 */
	-moz-border-radius: 6px;	 /* Firefox用 */
}
.HeaderMenu > .HeaderMenuRightStartItem .manualDownloadPageLink {
  color:  #fff !important;
}
.PartnerHeaderMenu > .HeaderMenuRightStartItem .manualDownloadPageLink {
  color:  #fff !important;
}
.HeaderMenu > .HeaderMenuRightStartItem:hover .manualDownloadPageLink {
  color: rgb(0, 106, 93) !important;
}
.PartnerHeaderMenu > .HeaderMenuRightStartItem:hover .manualDownloadPageLink {
  color: rgb(39,166,155)  !important;
}


.HeaderMenu > .HeaderMenuItem:hover,
.HeaderMenu > .HeaderMenuItem[class*="ant-menu-item-active"],
.HeaderMenu > .HeaderMenuItem[class*="ant-menu-item-open"],
.HeaderMenu > .HeaderMenuItem[class*="ant-menu-item-selected"],
.HeaderMenu > .HeaderMenuRightStartItem:hover,
.HeaderMenu > .HeaderMenuRightStartItem[class*="ant-menu-item-active"],
.HeaderMenu > .HeaderMenuRightStartItem[class*="ant-menu-item-open"],
.HeaderMenu > .HeaderMenuRightStartItem[class*="ant-menu-item-selected"],
.HeaderMenu > [class*="ant-menu-submenu"]:hover,
.HeaderMenu > [class*="ant-menu-submenu-active"],
.HeaderMenu > [class*="ant-menu-submenu-open"],
.HeaderMenu > [class*="ant-menu-submenu-selected"]  {
  color: rgb(0, 106, 93) !important;
  background-color: #fff !important;
  border: 3px solid rgb(0, 106, 93) !important;
  border-radius: 6px;
	-webkit-border-radius: 6px;	/* Safari,Google Chrome用 */
	-moz-border-radius: 6px;	 /* Firefox用 */
}

.PartnerHeaderMenu > .HeaderMenuItem:hover,
.PartnerHeaderMenu > .HeaderMenuItem[class$="ant-menu-item-active"],
.PartnerHeaderMenu > .HeaderMenuItem[class$="ant-menu-item-open"],
.PartnerHeaderMenu > .HeaderMenuItem[class$="ant-menu-item-selected"],
.PartnerHeaderMenu > .HeaderMenuRightStartItem:hover,
.PartnerHeaderMenu > .HeaderMenuRightStartItem[class$="ant-menu-item-active"],
.PartnerHeaderMenu > .HeaderMenuRightStartItem[class$="ant-menu-item-open"],
.PartnerHeaderMenu > .HeaderMenuRightStartItem[class$="ant-menu-item-selected"],
.PartnerHeaderMenu > [class*="ant-menu-submenu"]:hover,
.PartnerHeaderMenu > [class*="ant-menu-submenu-active"],
.PartnerHeaderMenu > [class*="ant-menu-submenu-open"],
.PartnerHeaderMenu > [class*="ant-menu-submenu-selected"]  {
  color: rgb(39,166,155)  !important;
  background-color: #fff !important;
  border: 3px solid rgb(39,166,155) !important;
  border-radius: 6px;
	-webkit-border-radius: 6px;	/* Safari,Google Chrome用 */
	-moz-border-radius: 6px;	 /* Firefox用 */
}

.PartnerHeaderMenu > [id="partnerWorkRecord"] {
  display: inline;
}

.PartnerHeaderMenu > [id="partnerMobileWorkRecord"] {
  display: none;
}

/* 協力の勤怠管理ボタン(PC用、スマホ用)の画面幅による切替 */
@media screen and (max-width: 851px) {
  .PartnerHeaderMenu > [id="partnerWorkRecord"] {
    display: none;
  }
  
  .PartnerHeaderMenu > [id="partnerMobileWorkRecord"] {
    display: inline;
  }
}
