@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

body {
  height: auto;
  margin: 0 !important;
  font-size: 0.78rem !important; 
   font-family: "Roboto", "Noto Sans JP", Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
  "Meiryo UI", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", serif !important;
}
.App > .ant-layout {
  background-color: #fff !important;
  position: absolute;
  height: 100%;
  width: 100%;
}
.ant-layout-content {
  padding: 30px 40px 0px 40px;
  /* padding:150px 40px 56px 40px; */
  overflow-y: auto;    
  overflow-x: hidden;
}

/* モーダル */
.ant-modal-content {
  overflow-y: auto;
}
.ant-modal-body {
  padding-top: 20px;
  padding-bottom: 10px;
}

.ant-modal-body > .ant-layout {
  background-color: #fff;
}
.ant-modal-body .ant-layout-content {
  overflow-y: hidden;   
  padding: 10px 10px 0px 10px;
}
.ant-modal-footer {
  text-align: center;
}

/* メニュー */
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border: 3px solid transparent;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
 .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
   .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-active,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-open {
  color:inherit;
  background-color: #eee;
  margin-bottom:0px;
  border-radius: 0px;
	-webkit-border-radius: 0px;	/* Safari,Google Chrome用 */
	-moz-border-radius: 0px;	 /* Firefox用 */
}
.ant-menu-submenu-title,
.ant-menu-submenu-title:hover {
  color:inherit;
}
.ant-menu-submenu {
  border-radius: 6px !important;
	-webkit-border-radius: 6px !important;	/* Safari,Google Chrome用 */
	-moz-border-radius: 6px!important;	 /* Firefox用 */
}

/* トグル */
.ant-switch-checked {
  background-color: rgb(0, 106, 93) !important;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 24px 0 10px;
}
.ant-switch-inner {
  display: block;
  margin: 0 10px 0 24px;
  color: #fff;
  font-size: 12px;
}
.ant-switch {
  margin:0 10px 4px 0;
  background-color:rgb(39,166,155);
}

/* ボタン */
.ant-btn {
  overflow: hidden;
  text-overflow:ellipsis;
  max-width: 20em;
}
.ant-btn-round {
  border-radius: 6px !important;
	-webkit-border-radius: 6px !important;	/* Safari,Google Chrome用 */
	-moz-border-radius: 6px!important;	 /* Firefox用 */
}

/* テーブル */
table {
  font-size: 0.9em;
  border: 1px solid rgba(0, 0, 0, .12);
}

th {
  height: 48px;
  background-color: #f5f5f5;
}

/* antdのスクロールauto指定が不能のため強制上書き */
.ant-table-content, .ant-table-body {
  overflow: auto !important;
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, .54);
  font-weight:700;
}
.ant-table-tbody > tr > td {
  border-top: 1px solid rgba(0, 0, 0, .12);
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

/* ページネーション */
.ant-pagination-options {
  margin-left: 2px;
  float: left;

}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next,
.ant-pagination-item {
  display: none;
}
@media (max-width: 575px) {
  .ant-pagination-options {
    display: block;
  }
}
/* テーブルフッター */
.ant-table-footer {
  border: none!important;
  background: none;
}

/* フォームアイテム */
.ant-form-item {
  margin-bottom:6px;
}

.hoverNotBackground .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset;
}