.criteriaSearchField {
    float:right;
 }
 .columnAlignLeft{
   text-align: left;
 }
 .columnAlignRight{
   text-align: right;
 }
 .formSelect{
   text-align: left;
 }
 .belongCompany{
   text-align: left;
   font-weight: bolder;
   margin-top: 10px;
   margin-bottom: 20px;
 }