.functionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(0, 106, 93);
  text-align: left;
  float: left;
}
.sarchCriteria {
  justify-content: flex-end;
  margin-bottom: 10px;
}

.radioGroup{
  width: 100%;
}

.manageName{
  text-align: left;
}

.headerLayout{
  display: flex;
}

.criteriaSearchField {
  float:right;
}

.formItem {
  display: inline;
}

.layout {
  height: 100%;
}