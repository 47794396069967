.holiday {
  /* background: #ffc0cb !important; */
  background: #ffffff !important;
  padding: 0px 0px !important;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.day {
  background: #ffffff !important;
  padding: 0px 0px !important;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.working {
  border-top: 1px dashed rgba(128, 106, 106, 0.4);
  white-space: nowrap;
}

.diffWorking {
  border-top: 1px dashed rgba(128, 106, 106, 0.4);
  background: #FFFF33 !important;
  white-space: nowrap;
}

.invalidInOutTime {
  text-align: center !important;
  color: #FF3333 !important;
}

.contentLeft {
  text-align: left !important;
}

.contentCenter {
  text-align: center !important;
}

.contentRight {
  text-align: right !important;
}

.totalDays {
  line-height: 38px;
  text-align: left;
}

.totalWorking {
  border-top: 1px dashed rgba(128, 106, 106, 0.4);
  text-align: left;
}

.totalDaysValue {
  line-height: 38px;
  text-align: right !important;
}

.totalWorkingValue {
  border-top: 1px dashed rgba(128, 106, 106, 0.4);
  text-align: right !important;
}

.workingRecordTitle {
  border-bottom: 1px dashed rgba(128, 106, 106, 0.4) !important;
  min-width: 1050px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.select {
  min-width: 53px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.status {
  min-width: 92px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
  white-space: nowrap;
}

.submitted {
  min-width: 130px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
  white-space: nowrap;
}

.worker {
  min-width: 114px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
  white-space: nowrap;
}

.firstPartner {
  min-width: 115px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
  white-space: nowrap;
}

.topHierarchyLevel {
  min-width: 80px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
  white-space: nowrap;
}

.item {
  min-width: 81px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.total {
  min-width: 53px;
  height: initial !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.headerTitle {
  font-weight: bold;
}

.select, .status, .submitted, .worker, .firstPartner, .item, .total, .workingRecordTitle > td {
  padding: 0px 0px 0px 2px !important;
  border-left: 0.5px solid rgba(128, 106, 106, 0.4);
}

.searchGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 10px;
  font-size:1.2em !important;
}

/* criteria 入力項目欄の設定 */
.criteriaInputField {
  float:left;
  max-width:1130px;
 }

 /* criteria 検索項目欄の設定 */
.criteriaSearchField {
 float:right;
}

.functionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(0, 106, 93);
  text-align: left;
}

/* 条件付き承認チェックボックス 固定 */
.forceCheckBoxArea {
  position: absolute;
  bottom:100px;
  left:50%;
  transform:translateX(-50%);
  width: 355px;
}

.duration {
  float: left;
  display: flex;
  flex-direction: column;
}

.durationSpan {
  margin-right:10px;
  margin-top:5px;
  float: left;
}

.topHierarchyLevelDuration {
  width:105px !important;
  text-align: right!important;
}

.topHierarchyLevelDuration::placeholder {
  text-align: left!important;
}
