.settings {
  margin-top: 60px;
}

.settings > div {
  margin: 20px 0;

}

.settings h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  border-left: 3px solid rgb(0, 106, 93);
  padding-left: 3px;
}

.item {
  display: flex;
  align-items: baseline;
  margin: 10px 0 10px 1em;
}
.itemKey {
  flex-basis: 100px;
}
.itemKey::after {
  content: '：';
}
.itemValue {
  flex-basis: auto;
}

.closingDateSelect {
  width: 5em;
  margin: 0 5px;
  text-align: right;
}

.primeCheckbox > span[class*="ant-checkbox-checked"] > span[class*="ant-checkbox-checked"],
.primeCheckbox > span[class*="ant-checkbox-checked"] > span[class*="ant-checkbox-inner"] {
  background-color: rgb(0, 106, 93) ;
  border-color:  rgb(0, 106, 93) !important;
}

/* 見出し */
.headline{
  font-weight: bolder;
  font-size: medium;
  padding-top: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 3em;
  border-bottom: 1px solid rgb(0, 106, 93);
}

.headline + .description {
  margin: 10px 0 10px 20px;
  text-align: left;
}
