.customButtonColor {
 background-color: rgb(100, 100, 100) ;
 color: #fff;
 margin-left:5px;
}

.customButtonColor:hover, .customButtonColor:focus {
 background-color: #fff;
 color:rgb(100, 100, 100);
 border-color: rgb(100, 100, 100);
}

.duration {
 display: flex;
 flex-direction: row;
 align-items: center; 
 justify-content: center;
}

.disabled {
  background-color: #fff;
}

.modalButton{
  display: inline-block;
  width: 80px !important;
  margin-top: 15px;
}

/* 入力項目の幅指定 */
.managementGroupName {
  width: 250px;
}
.fullName {
  width: 250px;
}
.originalQualificationName {
  width: 250px;
  height: 32px;
}

/* ボタンエリア固定化 */
.center {
  text-align: center;
}
.float {
  position: fixed!important;
  position : absolute; 
  bottom: 40px;
  left: 45%;
}
.float button {
  float: left;
  margin: 5px;
}
.buttonArea {
  background-color: #fff;
  min-height: 50px;
  bottom:56px;
} 
.customButtonColor {
  background-color: rgb(100, 100, 100) ;
  color: #fff;
  margin-left:5px;
}
.customButtonColor:hover, .customButtonColor:focus {
    background-color: #fff;
    color:rgb(100, 100, 100);
    border-color: rgb(100, 100, 100);
}

/* 所属企業名ラベル */
.belongCompany{
  text-align: left;
  font-weight: bolder;
  padding-top: 20px;
  margin-left: 20px;
  clear: both;
}
/* 見出し */
.headline{
  font-weight: bolder;
  font-size: medium;
  padding-top: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  border-bottom: 1px solid rgb(0, 106, 93);
}
/* 見出し */
.contentArea{
  margin-bottom: 30px;
}

/* テーブル項目 */
.delete{
  width: 80px;
}

/* コメント */
.comment {
  color: red;
}

.columnAlignLeft{
  text-align: left;
}