.tableRow{
  vertical-align: text-top;
}

.managementGroupLabels{
  margin-top:3px;
}

.managementGroupLabelButton{
  margin-left:12px;
}

.criteriaSearchField {
    float:right;
 }


.partnerCheckbox > span[class*="ant-checkbox-checked"] > span[class*="ant-checkbox-checked"],
.partnerCheckbox > span[class*="ant-checkbox-checked"] > span[class*="ant-checkbox-inner"] {
  background-color: rgb(39,166,155) ;
  border-color:  rgb(39,166,155) !important;
}

.partnerCheckbox span[class*="ant-checkbox-checked"]:hover,
.partnerCheckbox span[class*="ant-checkbox-checked"],
.partnerCheckbox span[class*="ant-checkbox-inner"] {
  background-color: #fff;
  border-color:  red  !important;
}


.partnerCheckbox span[class*="ant-checkbox-disabled"] span[class*="ant-checkbox-inner"]:hover,
.partnerCheckbox span[class*="ant-checkbox-disabled"] span[class*="ant-checkbox-inner"],
.partnerCheckbox span[class*="ant-checkbox ant-checkbox-checked ant-checkbox-disabled"] span[class*="ant-checkbox-inner"]:hover,
.partnerCheckbox span[class*="ant-checkbox ant-checkbox-checked ant-checkbox-disabled"] span[class*="ant-checkbox-inner"] {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}