.form {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.form:after{
  content: "";
  display: block;
  clear: both;
}

/* form要素はfloat-left */
.form [class*="ant-form-item"]{
  float:left;
  margin-bottom: 2px;
}
.form [class*="ant-form-item-explain"]{
  margin-top: 5px;
  margin-bottom: 0;
}

 /* コンテンツエリア */
.content {
  margin-top: 10px;
  margin-bottom: 20px;
}

 /* ラジオボタンエリア */
.radioArea {
  margin-left: 20px;
  text-align: left;
}

/* コメントエリア */
.commentArea {
  margin-top: 10px;
  margin-left: 40px;
  margin-bottom: 5px;
  text-align: left;
}

/* セレクトボタンが押された時のエリア */
.selectedArea {
  display: flex;
  flex-direction: column;
}

/* 検索条件エリア */
.criteriaArea {
  margin-left: 40px;
  text-align: left;
}

/* formItem */
.formItem{
  margin-bottom: 6px!important;
}

/* 項目別 */
.belongCompany{
  min-width: 200px;
  height: 32px;
}
.primeCompany{
  min-width: 200px;
  height: 32px;
}
.branch{
  min-width: 200px;
  height: 32px;
}
.startDate{
  width: 200px;
}
.password{
  width: 240px;
  height: 32px;
}

/* パスワードエリア 固定 */
.passwordArea {
  margin-left: 40px;
  text-align: left;
  position: relative;
}
.passwordCommentArea{
  margin-left: 60px;
  text-align: left;
}
.icon{
  border: none;
  position : absolute; 
  left:200px;
  height: 28px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.icon:hover{
  border: none;
  position : absolute; 
  left:200px;
  height: 28px;
  margin-top: 2px;
  margin-bottom: 2px;
}
/* ボタン固定 */
.buttonFixed {
  position: fixed!important;
  position : absolute; 
  bottom: 40px;
  left:50%;
  transform:translateX(-50%);
}

/* 手当金額の注釈 */
.amountNotion {
  text-align: left;
}