.radioGroup{
  display: flex;
}

.table{
  margin-bottom: 20px;
}

.tableRow{
  vertical-align: top;
}

.column{
  vertical-align: top;
}

.managementGroupLabels{
  margin-top:3px;
}

.referenceRange{
  justify-content:flex-end;
  align-items: center;
  margin-top:3px;
  margin-right:10px;
  display: flex;
}

.managementGroupLabelButton{
  margin-left:12px;
}
.functionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(0, 106, 93);
  text-align: left;
}
 .criteriaSearchField {
    float:right;
 }

 .grayText{
  color: lightgray;
}

.errorMessage {
  color: red;
  font-weight:600;
  font-size: 1.1em;
 }

.primeCheckbox > span[class*="ant-checkbox-checked"] > span[class*="ant-checkbox-checked"],
.primeCheckbox > span[class*="ant-checkbox-checked"] > span[class*="ant-checkbox-inner"] {
  background-color: rgb(0, 106, 93) ;
  border-color:  rgb(0, 106, 93) !important;

}
.primeCheckbox span[class*="ant-checkbox-checked"]:hover,
.primeCheckbox span[class*="ant-checkbox-checked"],
.primeCheckbox span[class*="ant-checkbox-inner"] {
  background-color: #fff;
  border-color:  red  !important;

}
.primeCheckbox span[class*="ant-checkbox-disabled"] span[class*="ant-checkbox-inner"]:hover,
.primeCheckbox span[class*="ant-checkbox-disabled"] span[class*="ant-checkbox-inner"],
.primeCheckbox span[class*="ant-checkbox ant-checkbox-checked ant-checkbox-disabled"] span[class*="ant-checkbox-inner"]:hover,
.primeCheckbox span[class*="ant-checkbox ant-checkbox-checked ant-checkbox-disabled"] span[class*="ant-checkbox-inner"] {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
 
/* criteria配下の部品の横幅を指定します。 */
.criteriaForm [class*="ant-form-item-control-input-content"] input:not([class*="ant-checkbox-input"]):not([class*="ant-radio-input"]),
.criteriaForm [class*="ant-form-item-control-input-content"] div:not([class*="ant-radio-group"]):not([class*="ant-picker"]),
.criteriaForm [class*="ant-form-item-control-input-content"] .ant-select {
 width:170px;
 height: 29px;
 margin-right:5px;
 font-size:0.9em !important;
}

