.loadingMessage {
  margin:auto;
  font-size:1.5em;
  font-weight:700;
}

.loadingPrimeMessage {
  margin:auto;
  font-size:1.5em;
  color: #006a5d;
  max-height: initial !important;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  width: 300px;
}

.loadingPartnerMessage {
  margin:auto;
  font-size:1.5em;
  color: #27a69b;
  max-height: initial !important;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  width: 300px;
}

.loading {
  height: inherit;
  align-content: center;
}

.wrapperLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  /* モーダルのz-indexがデフォルトで1000なので、それより大きい値を指定する必要がある */
  z-index: 1100;
}