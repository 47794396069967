.HeaderFixed {
  background-color: #fff;
  /* position: fixed!important;
  position : absolute;
  width:100%;
  top:0;
  z-index: 1000; */
}
.HeaderArea {
  padding: 10px 14px 6px 14px;
  min-height: 54px;
}
.HeaderlogoArea {
  display:-webkit-flex;
	display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: flex-start;
}
.LogoStyle {
  height:40px;
}
.HeaderInfoArea {
  display:-webkit-flex;
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items: flex-end;
  text-align: right;
}
.noWrap{
  display: inline-block;
  margin-left:10px;
}
.AlertNotContractPrimseService {
  color: red;
  font-size: 40px;
}