.primeCheckbox > span[class*="ant-checkbox-checked"] > span[class*="ant-checkbox-checked"],
.primeCheckbox > span[class*="ant-checkbox-checked"] > span[class*="ant-checkbox-inner"] {
  background-color: rgb(0, 106, 93) ;
  border-color:  rgb(0, 106, 93) ;

}
.primeCheckbox span[class*="ant-checkbox-checked"]:hover,
.primeCheckbox span[class*="ant-checkbox-checked"],
.primeCheckbox span[class*="ant-checkbox-inner"] {
  background-color: #fff;
  border-color:  rgb(0, 106, 93) !important;

}
.primeCheckbox span[class*="ant-checkbox-disabled"] span[class*="ant-checkbox-inner"]:hover,
.primeCheckbox span[class*="ant-checkbox-disabled"] span[class*="ant-checkbox-inner"],
.primeCheckbox span[class*="ant-checkbox ant-checkbox-checked ant-checkbox-disabled"] span[class*="ant-checkbox-inner"]:hover,
.primeCheckbox span[class*="ant-checkbox ant-checkbox-checked ant-checkbox-disabled"] span[class*="ant-checkbox-inner"] {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;

}
.partnerCheckbox > span[class*="ant-checkbox-checked"] > span[class*="ant-checkbox-checked"],
.partnerCheckbox > span[class*="ant-checkbox-checked"] > span[class*="ant-checkbox-inner"] {
  background-color: rgb(39,166,155) ;
  border-color:  rgb(39,166,155) ;

}
.partnerCheckbox span[class*="ant-checkbox-checked"]:hover,
.partnerCheckbox span[class*="ant-checkbox-checked"],
.partnerCheckbox span[class*="ant-checkbox-inner"] {
  background-color: #fff;
  border-color:  rgb(39,166,155)  !important;

}

.partnerCheckbox span[class*="ant-checkbox-disabled"] span[class*="ant-checkbox-inner"]:hover,
.partnerCheckbox span[class*="ant-checkbox-disabled"] span[class*="ant-checkbox-inner"],
.partnerCheckbox span[class*="ant-checkbox ant-checkbox-checked ant-checkbox-disabled"] span[class*="ant-checkbox-inner"]:hover,
.partnerCheckbox span[class*="ant-checkbox ant-checkbox-checked ant-checkbox-disabled"] span[class*="ant-checkbox-inner"] {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;

}