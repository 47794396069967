.FooterTable {
  background-color: #757575;
  min-height: 36px;
  bottom:0;
  font-size: 14px;
  display: table;
  table-layout: auto;
  width: 100%;
}
.FooterCell {
  display: table-cell;
  vertical-align: middle;
  color: aliceblue;
}