.commonMessage {
  text-align: left;
  width:90%;
  padding: 4px 15px 4px 37px;
  border:none;
  background-color: #FFF;
  content: "";
  display: block;
  clear: both;
}
.iconSize > svg {
  width:1.0em;
  height:1.0em;
}
.commonMessage > [class*="ant-alert-icon"] {
  top:8px;
}
.commonMessage > [class*="ant-alert-message"] {
  color:red;
  font-size:1.1em;
  font-weight:600;
}
.commonSuccessMessage {
  text-align: left;
  width:90%;
  padding: 4px 15px 4px 0px;
  border:none;
  background-color: #FFF;
  content: "";
  display: block;
  clear: both;
}
.successIconSize > svg {
  color:#6495ED;
  width:1.0em;
  height:1.0em;
}
.commonSuccessMessage > [class*="ant-alert-icon"] {
  top:8px;
}
.commonSuccessMessage > [class*="ant-alert-message"] {
  color:#6495ED;
  font-size:1.1em;
  font-weight:600;
}