.functionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(0, 106, 93);
  text-align: left;
}

.existSubmitOrConfirmed{
  background-color: yellow !important; 
}

.criteriaSearchFieldLeft{
  margin-left:4px;
  float: left;
}

.criteriaSearchFieldLeftBox{
  margin-left:4px;
  float: left;
  max-width: 820px;
}

.criteriaSearchFieldRight{
  margin-right:4px;
  float: right;
}

.columnAlignLeft{
  text-align: left;
}

.duration {
  float: left;
  display: flex;
  flex-direction: column;
}

.durationSpan {
  margin-right:10px;
  margin-top:5px;
  float: left;
}