.functionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(0, 106, 93);
  text-align: left;
  font-size:16px;
  margin-bottom:10px;
  width:300px;
  float:left;    
}
.partnerfunctionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(39,166,155);
  text-align: left;
  font-size:16px;
  margin-bottom:10px;   
  width:300px;
  float:left;
}