.remarks {
  white-space: pre-wrap;
  word-break: break-all;
}
.customButtonColor {
  background-color: rgb(100, 100, 100);
  color: #fff;
  margin-left: 5px;
}
.customButtonColor:hover,
.customButtonColor:focus {
  background-color: #fff;
  color: rgb(100, 100, 100);
  border-color: rgb(100, 100, 100);
}
