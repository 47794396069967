.authButton, .authButton:focus {
  background-color: rgb(0, 106, 93) ;
  color: #fff;
  margin-left:5px;
}
.authButton:hover {
  background-color: #fff;
  color:rgb(0, 106, 93);
  border-color: rgb(0, 106, 93);
}
.partnerAuthButton, .partnerAuthButton:focus {
  background-color: rgb(39,166,155) ;
  color: #fff;
  margin-left:5px;
}
.partnerAuthButton:hover {
  background-color: #fff;
  color:rgb(39,166,155);
  border-color: rgb(39,166,155);
}
.displayNone {
  display: none;
}
.authButtonFixed, .authButtonFixed:focus {
  background-color: rgb(0, 106, 93) ;
  color: #fff;
  border-color: rgb(0, 106, 93);
  margin-left:5px;
}
.authButtonFixed:hover {
  background-color: #fff;
  color:rgb(0, 106, 93);
  border-color: rgb(0, 106, 93);
}

.backButtonFixed, .backButtonFixed:focus {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, .38);
  border-color: #dcedc8;
  margin-left:5px;
}

.backButtonFixed:hover {
  background-color: #fff;
  color: rgba(0, 0, 0, .38);
  border-color: rgba(0, 0, 0, .38);
}