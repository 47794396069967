.criteriaSearchField{
  float: right;
}
.step{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 300px;
  padding-bottom: 10px;
  font-size:1.2em !important;
}
.layout{
  height: 100%;
}