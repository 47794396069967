.day {
  background: #ffffff !important;
  padding: 0px 0px !important;
  height: initial !important;
}

.totalDays {
  background: #ffffff !important;
}

.totalWorking {
  background: #ffffff !important;
}

.working {
  white-space: nowrap;
}

.diffWorking {
  background: #FFFF33 !important;
  white-space: nowrap;
}

.invalidInOutTime {
  text-align: center !important;
  color: #FF3333 !important;
}

.worktimeConfirmTextDate {
  color: #FF3333 !important;
}

.contentLeft {
  text-align: left !important;
}

.contentCenter {
  text-align: center !important;
}

.contentRight {
  text-align: right !important;
}

.headerTitle {
  font-weight: bold;
  margin-left : 20px !important;
}

.searchGroup {
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 10px;
  font-size:1.2em !important;
}

.flexContainer {
  display:flex;
}

.partnerfunctionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(39,166,155);
  text-align: left;
  font-size:16px;
  margin-bottom:10px;   
  width:300px;
}

.verticalTitle{
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  -moz-writing-mode: vertical-lr;
  margin: auto;
}

.titleBackgound{
  background: #fafafa;
  height: 110px;
}

.tableCell{
  padding: 5px 0px !important;
}

.content{
  padding: 30px 10px 3px 10px;
  overflow-y: auto;    
  overflow-x: hidden;
}

.workerAttendanceRecordTitle{
  color: rgba(0, 0, 0, .54);
  font-weight: 700;
}