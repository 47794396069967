.innerHTML {
  overflow-y:scroll;
  overflow-x:hidden;
  width:95%;
  height:80%;
  border:1px solid #ccc;
  margin: 20px auto;
  text-align: left;
  background-color: #fff;
}
.buttonArea{
  background-color: #fff;
  min-height: 100px;
}
.checkbox{
  margin-bottom: 20px;
}

.loading {
  align-content: center;
  height: 100%;
}

.loadingPrimeMessage {
  margin:auto;
  font-size:1.5em;
  color: #006a5d;
  max-height: initial !important;
}

.loadingPartnerMessage {
  margin:auto;
  font-size:1.5em;
  color: #27a69b;
  max-height: initial !important;
}