 .customButtonColor {
   background-color: rgb(100, 100, 100) ;
   color: #fff;
   margin-left:5px;
 }
 .customButtonColor:hover, .customButtonColor:focus {
     background-color: #fff;
     color:rgb(100, 100, 100);
     border-color: rgb(100, 100, 100);
 }

 .requestConfirmButton {
  background-color: rgb(51,102,204) ;
  color: #fff;
  margin-left:5px;
 }
.cancelRequestConfirmButton {
  background-color: rgb(39,162,218) ;
  color: #fff;
  margin-left:5px;
 }
 .confirmButton {
  background-color: rgb(51,102,0) ;
  color: #fff;
  margin-left:5px;
 }
.remandRequestConfirmButton {
  background-color: rgb(39,162,218) ;
  color: #fff;
  margin-left:5px;
 }
 .approveButton {
  background-color: rgb(237, 125, 49) ;
  color: #fff;
  margin-left:5px;
 }
 .remandConfirmButton {
  background-color: rgb(214,51,153) ;
  color: #fff;
  margin-left:5px;
 }
 .cancelApproveButton {
  background-color: rgb(214,51,153) ;
  color: #fff;
  margin-left:5px;
 }
 /* 独自資格一覧の行 */
 .qualificationRow {
  height: 115px
 }

 .belongCompany{
   text-align: left;
   font-weight: bolder;
   padding-top: 20px;
   margin-bottom: 20px;
   margin-left: 20px;
   clear: both;
 }
 /* 見出し */
 .headline{
   font-weight: bolder;
   font-size: medium;
   padding-top: 10px;
   margin-bottom: 20px;
   margin-left: 20px;
   border-bottom: 1px solid rgb(0, 106, 93);
 }
 /* 見出し */
 .contentArea{
   margin-bottom: 30px;
 }

 /** 備考 */
 .remarks {
  white-space: pre-wrap;
  word-break: break-all;
 }
 .columnAlignLeft{
  text-align: left;
 }