.detailForm {
  width:70%;
  border: none;
  margin: 10px 0 0 20px;
  border-left:1px solid #ccc;
  border-bottom:1px solid #ccc;
  clear: both;
}

@media (max-width: 1000px) {
  .detailForm{
    width:90%;
    border-left:1px solid #ccc;
    border-bottom:1px solid #ccc;
   }
}

@media (max-width: 575px) {
  .detailForm{
    width:90%;
    border: none;
  }
}   

.detailForm > [class*="ant-form-item"] {
  margin-bottom:0px;
  border-top:1px solid #ccc;
  border-right:1px solid #ccc;
}
.detailForm [class*="ant-form-item-label"] {
  display:-webkit-flex;
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items: center;
  font-weight:700;
  background-color: #eee;
  min-width:150px;
  max-width:150px;
  border-right: 1px solid #ccc;
}
.detailForm [class*="ant-form-item-label"] > label::after {
  content: '';
}
.detailForm [class*="ant-form-item-control"] {
  padding:5px 0 5px 0; 
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start; */
}
.detailForm [class*="ant-form-item-control-input"] > div {
  width: 100%;
}
.detailForm [class*="ant-form-item-control-input-content"] > span,
.detailForm [class*="ant-form-item-control-input-content"] > div,
.detailForm [class*="ant-form-item-control-input-content"] > input,
.detailForm [class*="ant-form-item-control-input-content"] > textarea {  
  display:-webkit-flex;
  display:flex;
  justify-content:flex-start;
  align-items: center;
  margin-left:8px;
  margin-right:8px;
  text-align: left;
}
.detailForm [class*="ant-row"]  [class*="ant-form-item"] {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .detailForm  {
    margin: 10px 0 0 20px;
    border:none;
  }
  .detailForm > [class*="ant-form-item"]{
    margin-bottom:0px;
    border:none;
    margin-left:10px;
    margin-bottom: 0;
  }
  .detailForm [class*="ant-form-item-label"] > label::before {
    content: '・';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
  .detailForm [class*="ant-form-item-label"] {
    display:-webkit-flex;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items: center;
    background-color: #fff;
    font-weight:700;
    border:none;
  }
  .detailForm [class*="ant-row"] > [class*="ant-form-item-control"] {
    margin-left:10px;
  }
}

/* フォーム部品 */
.detailForm [class*="ant-form-item-control-input-content"] > input,
.detailForm [class*="ant-form-item-control-input-content"] > div,
.detailForm [class*="ant-form-item-control-input-content"] > [class*="ant-select"] {
  width:240px;
}
.detailForm [class*="ant-form-item-control-input-content"]  > textarea {
  width:90%;
}
.detailForm [class*="ant-form-item-explain"] {
  display:-webkit-flex;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  margin-left:8px;
  margin-right:8px;
}

.detailForm [class*="ant-input-group-addon"] {
  /* INPUTの前後のテキスト用 */
 border: none;
 margin-left:10px;
 margin-right:10px;
 background-color:inherit;  
}

  /* 前後のテキスト付きINPUTの高さ */
.detailForm [class*="ant-input-group"] > input {
  height: 32px;
}

.detailForm [class*="ant-form-item-has-error"]  [class*="ant-input-group-addon"] {
  color: rgba(0, 0, 0, 0.65);
}

.detailForm  [class*="ant-form-item-control-input-content"]  > div[class*='ant-picker']  {
  width: 130px;
} 
