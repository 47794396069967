.primeRadio span[class*="ant-radio-inner"],
.primeRadio span[class*="ant-radio-inner"]:after  {
  border-color: rgb(0, 106, 93) !important;
}
.primeRadio span[class*="ant-radio-inner"]:after  {
  background-color: rgb(0, 106, 93) !important;
}
.primeRadio input {
  color:rgb(0, 106, 93) !important;
}

.partnerRadio span[class*="ant-radio-inner"],
.partnerRadio span[class*="ant-radio-inner"]:after  {
  border-color: rgb(39,166,155) !important;
}
.partnerRadio span[class*="ant-radio-inner"]:after  {
  background-color: rgb(39,166,155) !important;
}
.partnerRadio input {
  color:rgb(39,166,155) !important;
}
