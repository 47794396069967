.criteriaForm {
  /* display:-webkit-flex;
	display:flex;
  justify-content:flex-end;  */
  /* float:right;
  display:contents; */
  display: inline;
}

/* criteriaエリア描画後はfloat-leftを解除します。 */
.criteriaForm:after{
  content: "";
  display: block;
  clear: both;
}

/* criteria配下のForm要素およびボタンはfloat-left */
.criteriaForm [class*="ant-form-item"],
.criteriaForm button {
  float:left;
 }
 
/* criteria配下の部品の横幅を指定します。 */
.criteriaForm [class*="ant-form-item-control-input-content"] input:not([class*="ant-checkbox-input"]):not([class*="ant-radio-input"]),
.criteriaForm [class*="ant-form-item-control-input-content"] div:not([class*="ant-radio-group"]):not([class*="ant-picker"]),
.criteriaForm [class*="ant-form-item-control-input-content"] .ant-select {
 width:140px;
 height: 29px;
 margin-right:5px;
 font-size:0.9em !important;
}

.criteriaForm [class*="ant-form"] label{
  font-size:0.9em !important;
}

.criteriaForm [class*="ant-form-item"] {
  margin-bottom: 4px;
}

.criteriaForm [class*="ant-form"] div[class*="ant-picker"]{
  width:120px;
  height: 29px;
  font-size:0.95em !important;
}

.criteriaForm [class*="ant-form-item-control-input-content"] [class*="ant-picker"] [class*="ant-picker-input"] input{
  width: 80px ;
  margin-right: 0 ;
  font-size: 1em !important;
}

.criteriaForm [class*="ant-select-selection-item"] {
 font-size: 1.1em !important;
}