.partnerfunctionTitle {
  padding-left: 5px;
  border-left: 5px solid rgb(39,166,155);
  text-align: left;
  font-size:16px;
  margin-bottom:10px;   
  width:300px;
}

.columnAlignLeft{
  text-align: left;
}

.contentHeader {
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  height: 100px;
  width: 50%;
  float: left;
  font-size:1.2em !important;
}

.headerTitle {
  font-weight: bold;
  margin: auto 0px auto 20px !important;
}

.flexContainer {
  display:flex;
}

.searchButton{
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primeCompanyAndProjectCell{
  padding: 10px 0px;
}

.longText{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.firstPartnerCompanyCell {
  border-top: 1px solid rgba(0, 0, 0, .12);
  padding: 5px 0px;
}

.tableCell{
  padding: 0px 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 0;
}

.modalSearchItemGroup{
  margin: 0px 35px;
}

.modalSearchItem{
  contain: content;
}

.modalSearchItemInput{
  margin: 0px 20px;
}

.modalInternalButton{
  margin: 0px 20px;
}

.projectName{
  display: inline-block;
}

.content{
  padding: 30px 10px 30px 10px;
  overflow-y: auto;    
  overflow-x: hidden;
}

/* 勤怠年月切替ボタン */
.changeMonthButton{
  color: #27a69b;
  margin: 4px;
  font-size: x-large;
}